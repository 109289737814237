<div class="jumbotron pt-0">
  <div class="row text-right">
    <div class="col-lg-12">
<h4 id="time">00:00:00</h4>
<p id="date">Fecha</p>
    </div>

  </div>
  <div class="row">
    <div class="col-lg-3 offset-5 ">
      <div class="form-row">
        <div class="form-group">
          <label for="codigo">Codigo</label>
          <input type="text" class="form-control" [(ngModel)]="codigo" placeholder="PPAGSI6" id="codigo">
        </div>


      </div>
      <div class="w-100 ">
        <button class="btn btn-success" (click)="buscar()">Buscar</button>
      </div>

    </div>
  </div>

  <div class="row mt-5" *ngIf="datos != null">
    <div class="row">
      <div class="col-lg-5" >
        <h2>{{datos.nombre}}</h2>
        <h5>{{datos.puesto}}</h5>
        <h6>Nivel academico: {{datos.nivelAcademico}}</h6>
        <h6>Reconocimientos: {{datos.reconocimientos}}</h6>
        <h6>Constancias: {{datos.constancias}}</h6>
        <h6>Arrestos: {{datos.arrestos}}</h6>
        <h6>Antigüedad: {{datos.antiguedad}}</h6>
        <h4>{{datos.puntuaje}} puntos sin examenes</h4>
      </div>
      <div class="col-lg-7" *ngIf="califas!=null">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th class="text-center">Nombre Materia</th>
              <th class="text-center">Calificacion</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let a of califas">
              <td class="text-center">{{a.nombre}}</td>
              <td class="text-center">{{a.calificacion}}</td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <td class="text-right">Total:</td>
              <td class="text-center">{{calificacion}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="col-lg-12 text-center">
        <h1>Puntuacion total: {{total}}</h1>
      </div>
    </div>

  </div>
</div>
