<div class="jumbotron no-print pt-2 mb-0 pb-5">
  <div class="row">
    <div class="col-lg-3 text-right offset-lg-9">
      <h5 (click)="cerrarSesion()" style="cursor: pointer;"><i class="fas fa-power-off"></i>Cerrar sesión</h5>
    </div>
  </div>

<div class="no-print mt-0 p-0">
  <div class="row">
    <div class="col-lg-3 ">
      <a routerLink="/rhB">Ir a registro de bomberos</a>
    </div>
    <div class="col-lg-5 offset-md-7  mt-2 mb-4">
      <img src="assets/logoGob2.png" width="100%" alt="" class="no-print">
    </div>
  </div>

  <h2>Policía</h2>
  <div class="row">
    <div class="col-lg-2 offset-lg-10">
      <h3><b>Folio: <span style="color:red">{{folio}}</span></b></h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="impe">IMPE</label>
      <input type="number"  [(ngModel)]="impe" name="impe" class="form-control" id="impe" (focusout)="check($event)" (keyup)="check($event)">
    </div>
    <div class="form-group col-md-3">
      <label for="apaterno">Apellido paterno</label>
      <input type="text" [(ngModel)]="vato.apaterno" class="form-control" id="apaterno">
    </div>
    <div class="form-group col-md-3">
      <label for="amaterno">Apellido Materno</label>
      <input type="text" [(ngModel)]="vato.amaterno" class="form-control" id="amaterno">
    </div>
    <div class="form-group col-md-3">
      <label for="nombre">Nombre</label>
      <input type="text" [(ngModel)]="vato.nombre" class="form-control" id="nombre">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="puesto">Puesto</label>
      <input type="text" [(ngModel)]="vato.puesto" class="form-control" id="puesto">
    </div>


    <div class="form-group col-md-9">
      <label for="Reconocimientos">Reconocimientos</label>
      <!--input type="text" [(ngModel)]="vato.reconocimientos" class="form-control" id="Reconocimientos"-->
      <div class="form-row">
        <div class="form-group col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input type="checkbox" (change)="alto()" id="altoImp" aria-label="Checkbox for following text input">
              </div>
            </div>

            <input type="text" class="form-control" value="Alto impacto" disabled  aria-label="Text input with checkbox">
            <input type="number" class="form-control" id="altoImpacto" [(ngModel)]="altoImpactoTot" (keyup)="ponerTotAlto()" placeholder="Total"  disabled aria-label="Text input with checkbox">


          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input type="checkbox" id="polic" (change)="poli()" aria-label="Checkbox for following text input">
              </div>
            </div>
            <input type="text" class="form-control" value="Policía del mes" disabled aria-label="Text input with checkbox">
            <input type="number" class="form-control" id="polimes" (keyup)="ponerTotPoli()" [(ngModel)]="poliMesTot"  placeholder="Total" disabled aria-label="Text input with checkbox">
          </div>
        </div>

        <div class="form-group col-md-4 d-none">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input type="checkbox" id="asig" (change)="asignacion()" aria-label="Checkbox for following text input">
              </div>
            </div>
            <input type="text" class="form-control" value="Asignación Especial" disabled aria-label="Text input with checkbox">

          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="capacitaciones">Constancias de la Escuela de Policía (mayo 2018- dic 2020)</label>
      <input type="number" pattern="[0-5]{5}" [(ngModel)]="vato.capacitaciones" class="form-control" id="capacitaciones">
    </div>
    <div class="form-group col-md-3">
    <label for="Reconocimientos">Constancia</label>
      <!--input type="text" [(ngModel)]="vato.reconocimientos" class="form-control" id="Reconocimientos"-->
      <div class="form-row">

        <div class="form-group col-md-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input type="checkbox" id="extra1" (change)="extra()" aria-label="Checkbox for following text input">
              </div>
            </div>
            <input type="text" class="form-control" value="Diplomado para mando o mando policial" disabled aria-label="Text input with checkbox">

          </div>
        </div>



      </div>
      </div>


  </div>

  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="arrestos">Arrestos y Sanciones (Total)</label>
      <input type="number" [(ngModel)]="vato.arrestos" class="form-control" id="arrestos">
    </div>
    <div class="form-group col-md-3">
      <label for="antiguedad">Antigüedad</label>
      <input type="number" [(ngModel)]="vato.antiguedad" class="form-control" id="antiguedad">
    </div>
    <div class="form-group col-md-3">
      <label for="nivel">Nivel Académico</label>
      <select name="nivel" id="eCivil" [(ngModel)]="vato.nivelAcademico" class="form-control" >
        <option value="">Seleccione..</option>
        <!--ption value="PRIMARIA">Primaria</option-->
        <option value="SECUNDARIA">Secundaria</option>
        <!--option value="SECRETARIADO/COMERCIO"> Secretariado / Comercio</option-->
        <option value="MEDIO SUPERIOR">Media Superior</option>
        <option value="TECNICO SUPERIOR UNIVERSITARIO TSU">Técnico Superior Universitario (TSU)</option>
        <option value="SUPERIOR LICENCIATURA">Licenciatura</option>
        <option value="SUPERIOR INGENIERIA">Ingeniería</option>
        <option value="MAESTRIA">Maestría</option>
        <option value="DOCTORADO">Doctorado</option>
        <!--option value="SIN DATO">Sin dato</option>
        <option value="INF. NO ESPECIFICA">Inf. no específica</option-->
    </select>

    </div>
    <div class="form-group col-md-3">
      <label for="nivel">Subdirección</label>
      <select name="nivel" id="eCivil" [(ngModel)]="vato.depa2" class="form-control" >
        <option value="">Seleccione..</option>
        <option value="Despliegue">Despliegue</option>
        <option value="Táctico">Táctico</option>
    </select>

    </div>


  </div>


<div class="container-fluid mt-2 p-0 no-print">

  <table border="1px" style="width: 100%;">
    <tr>
      <td>
        Nombre:
      </td>
      <td colspan="2">{{vato.apaterno}} {{vato.amaterno}} {{vato.nombre}}</td>

    </tr>

    <tr>
      <td>Puesto:</td>
      <td colspan="2">{{vato.puesto}}</td>

    </tr>
    <tr>
      <td>Subdirección:</td>
      <td colspan="2">{{vato.depa2}}</td>

    </tr>
    <tr>
      <td colspan="2">&nbsp;</td>

      <td>Puntos</td>
    </tr>
    <tr>
      <td>Escolaridad</td>
      <td>{{vato.nivelAcademico}}</td>
      <td>{{checkPuntos()}}</td>
    </tr>
    <tr>
      <td>Reconocimientos</td>
      <td>{{altoImpacto}} <br>{{poliMes}} <br>{{asignation}}</td>
      <td>{{checkPuntosRec()}}</td>
    </tr>
    <tr>
      <td>Capacitaciones</td>
      <td>{{vato.capacitaciones}} constancias <br>{{diplomado}} </td>
      <td>{{checkPuntosCap()}}</td>
    </tr>
    <tr>
      <td>Arrestos y sanciones</td>
      <td>{{vato.arrestos}} arrestos y sanciones</td>
      <td>{{checkPuntosArr()}}</td>
    </tr>
    <tr>
      <td>Antigüedad</td>
      <td>{{vato.antiguedad}} años</td>
      <td>{{checkPuntosAnt()}}</td>
    </tr>
    <tr>
      <td colspan="2" class="text-right">Total:&nbsp;</td>
      <td>{{totalPuntos}}</td>
    </tr>
  </table>
</div>
<div class="row mb-4 mt-4">
  <div class="col-lg-12">
    <h6>La información aquí descrita es  responsabilidad de quien captura y de quién recibe, ofreciendo así la veracidad de la misma para el proceso de Promoción y Ascenso de Grado Superior Inmediato 1/2020</h6>
  </div>
 </div>
  <br>
  <button type="submit" class="btn btn-primary no-print" *ngIf="folio==null && !edit" (click)="guardarDatos()">Registrar</button>
      <button type="submit" class="btn btn-primary no-print" *ngIf="edit" (click)="actualizar()">Actualizar</button>
  <button type="submit" class="btn btn-primary no-print" *ngIf="folio!=null" (click)="imprimir()">imprimir</button>
  &nbsp;<button class="btn btn-primary no-print" (click)="mensaje()">vaciar datos</button>

  <div  class="text-nowrap mt-5">
    <table style="overflow-x: scroll;overflow-y: scroll; max-height:500px!important ;" class="table table-hover  " id="tablilla" >
<thead class="thead-dark">
  <tr>
    <th class="text-center">
      Folio Comision
    </th>
    <th class="text-center">impe</th>
    <th class="text-center">nombre</th>
    <th class="text-center">puesto</th>
    <th class="text-center">puntos</th>
    <th class="text-center">Editar</th>
  </tr>
</thead>
   <tbody>
    <tr *ngFor="let a of registrados; let i=index2; let lastcall2=last;" >
      <td class="text-center" >PPAGSI{{a.id}}</td>
      <td class="text-center">{{a.impe}}</td>
      <td class="text-center">{{a.nombre}} {{a.apaterno}} {{a.amaterno}}</td>
      <td class="text-center">{{a.puesto}}</td>
      <td class="text-center">{{a.puntuaje}}</td>
      <td class="text-center"  (click)="editar(a)">editar<i class="fas fa-pen" style="cursor: pointer;" (click)="editar(a)"></i></td>
      <span *ngIf="lastcall2">{{iniciar()}}</span>
    </tr>

   </tbody>

    </table>
  </div>


</div>
</div>




<div class="imprimir print-only" id="imprimir">
  <div class="container">
   <div class="row">
    <div class="col-lg-4 offset-md-8">
      <img src="assets/logoGob2.png" width="100%" alt="" class="print-">
    </div>
    <div class="col-lg-4 mt-4 offset-md-8">
      <p>Folio: <span style="color: red;"><b>{{folio}}</b></span></p>
    </div>
   </div>
   <div class="row">
     <div class="col-lg-12">
      <h3 style="text-transform: uppercase;">{{vato.apaterno}} {{vato.amaterno}} {{vato.nombre}}</h3>
     </div>
<div class="col-lg-12">
  <h4>{{vato.puesto}}</h4>
</div>
<div class="col-lg-12">
  Subdirección: <h4>{{vato.depa2}}</h4>
</div>

   </div>

<div class="row mb-5">
<div class="col-lg-12">
  <table  style="width: 100%;" id="tablilla">

    <tr>
      <td colspan="2">&nbsp;</td>

      <td>Puntos</td>
    </tr>
    <tr>
      <td>Escolaridad</td>
      <td>{{vato.nivelAcademico}}</td>
      <td>{{checkPuntos()}}</td>
    </tr>
    <tr>
      <td>Reconocimientos</td>
      <td>{{altoImpacto}}  <br>{{poliMes}} </td>
      <td>{{checkPuntosRec()}}</td>
    </tr>
    <tr>
      <td>Capacitaciones</td>
      <td>{{vato.capacitaciones}} capacitaciones <br>{{diplomado}}</td>
      <td>{{checkPuntosCap()}}</td>
    </tr>
    <tr>
      <td>Arrestos y sanciones</td>
      <td>{{vato.arrestos}}  arrestos y sanciones</td>
      <td>{{checkPuntosArr()}}</td>
    </tr>
    <tr>
      <td>Antigüedad</td>
      <td>{{vato.antiguedad}} años</td>
      <td>{{checkPuntosAnt()}}</td>
    </tr>
    <tr>
      <td colspan="2" class="text-right">Total:&nbsp;</td>
      <td>{{totalPuntos}}</td>
    </tr>
  </table>
</div>
</div>


   <div class="row mb-5">
    <div class="col-lg-12">
      <h6>La información aquí descrita es  responsabilidad de quien captura y de quién recibe, ofreciendo así la veracidad de la misma para el proceso de Promoción y Ascenso de Grado Superior Inmediato 1/2020</h6>
    </div>
   </div>


   <table style="width: 100%;">
    <tr>
      <td class="text-center">_______________________________________________</td>
      <td colspan="3" >&nbsp;</td>
      <td class="text-center">_______________________________________________</td>
    </tr>
    <tr>
      <td class="text-center"><h6 style="text-transform: uppercase;">{{vato.nombre}} {{vato.apaterno}} {{vato.amaterno}}</h6></td>
      <td colspan="1" >&nbsp;</td>
      <td colspan="1" class="text-center">____________________________</td>
      <td colspan="1" >&nbsp;</td>
      <td class="text-center"> <h6 style="text-transform: uppercase;">{{nombre}} {{apaterno}} {{amaterno}}</h6></td>
    </tr>
    <tr>
      <td class="text-center"><h6 style="text-transform: uppercase;">{{vato.puesto}}</h6></td>
      <td colspan="1" >&nbsp;</td>
      <td colspan="1" class="text-center">SELLO</td>
      <td colspan="1" >&nbsp;</td>
      <td class="text-center"> <h6 style="text-transform: uppercase;">RECURSOS HUMANOS</h6></td>
    </tr>
   </table>
  </div>
</div>
