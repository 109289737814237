import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { RHComponent } from "./rh/rh.component";
import { RhBComponent } from "./rh-b/rh-b.component";
import { ComisionComponent } from "./comision/comision.component";
import { EyfcComponent } from "./eyfc/eyfc.component";
import { CalificacionesComponent } from './calificaciones/calificaciones.component';
import { AltaCalificacionesComponent } from './alta-calificaciones/alta-calificaciones.component';

const routes: Routes = [
  {
    path: 'rh',
    component: RHComponent,
  },
  {
    path: 'rhB',
    component: RhBComponent,
  },
  {
    path: 'chj',
    component: ComisionComponent,
  },
  {
    path: 'eyfc',
    component: EyfcComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'altaCalifas',
    component: AltaCalificacionesComponent,
  },
  {
    path: 'calificaciones',
    component: CalificacionesComponent,
  },
  {
    path: '**',
    component: CalificacionesComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
