import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConexionApiService {
  url="http://proceso.policiachihuahua.gob.mx/apiProceso/";
  //url="http://localhost:80/apiProceso/";
  constructor(private http:HttpClient) { }

  obtenerUsuario(num,pass){
    return this.http.get(`${this.url}obtenerUsuario.php?num=${num}&pass=${pass}`);
  }
  obtenerDatos(impe){
    return this.http.get(`${this.url}obtenerDatos.php?impe=${impe}`);
  }
  altaRegistro(vato){
    return this.http.post(`${this.url}AltaRegistro2.php`,JSON.stringify(vato));
  }
  actualizaRegistro(vato){
    return this.http.post(`${this.url}ActualizaRegistro.php`,JSON.stringify(vato));
  }
  altaRegistroComision(vato){
    return this.http.post(`${this.url}AltaRegistroComision.php`,JSON.stringify(vato));
  }
  obtenerDatosEnse(depa){
    return this.http.get(`${this.url}obtenerDatosEnse.php?depa=${depa}`);
  }
  actualizarEstatus(estatus,id){
    return this.http.get(`${this.url}Actualizarestatus.php?status=${estatus}&id=${id}`);
  }
  altaMateria(nombre,depa){
    return this.http.get(`${this.url}AltaMateria.php?nombre=${nombre}&depa=${depa}`);
  }
  obtenerMaterias(){
    return this.http.get(`${this.url}obtenerDatosMaterias.php`);
  }
  obtenerMateriasDepa(depa){
    return this.http.get(`${this.url}obtenerMateriasUno.php?depa=${depa}`);
  }
  obtenerMateriasPersona(id){
    return this.http.get(`${this.url}obtenerMateriasPersona.php?id=${id}`);
  }
  obtenerRegistrados(codigo){
    return this.http.get(`${this.url}obtenerRegistrados.php?codigo=${codigo}`);
  }
  obtenerCalifas(idMateria){
    return this.http.get(`${this.url}obtenerCalificaciones.php?idMateria=${idMateria}`);
  }
  updateCalifas(impe,idMateria,calificacion){
    return this.http.get(`${this.url}altaCalificaciones.php?idMateria=${idMateria}&impe=${impe}&calificacion=${calificacion}`);
  }
  obtenerRegistradosComision(){
    return this.http.get(`${this.url}obtenerRegistradosComision.php`);
  }
  actualizarComision(carpeta,numExpediente,asunto,fecha,impe){
    return this.http.get(`${this.url}ActualizarComision.php?carpeta=${carpeta}&numExpediente=${numExpediente}&asunto=${asunto}&fecha=${fecha}&impe=${impe}`);
  }

  obtenerRegistradosRhPolicias(){
    return this.http.get(`${this.url}obtenerRegistradosRhPolicias.php`);
  }

  obtenerCalificacionesGeneral(){
    return this.http.get(`${this.url}obtenerCalificacionesGeneral.php`);
  }

  altaGrupo(nombre,depa){
    return this.http.get(`${this.url}AltaGrupo.php?grupo=${nombre}&depa=${depa}`);
  }
  obtenerGrupos(){
    return this.http.get(`${this.url}obtenerGrupos.php`);
  }
  ponerGrupo(idGrupo,idRegistro){
    return this.http.get(`${this.url}ponerGrupo.php?grupo=${idGrupo}&registro=${idRegistro}`);
  }
  

}
