import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare var $: any;
declare function MensajeRegistroCompletoComision(x):any;
declare function ConfirmarReload():any;
@Component({
  selector: 'app-comision',
  templateUrl: './comision.component.html',
  styleUrls: ['./comision.component.css']
})
export class ComisionComponent implements OnInit {
  impe=null;
  registrados=null;
  edit=false;
  vato={
    impe:null,
    nombre:"",
    apaterno:"",
    amaterno:"",
    puesto:"",
    carpeta:"SELECCIONE",
    impeUsuario:0,
    expediente:"",
    asunto:"",
    fecha:null
  }
  resultao=null;
  impeRH="";
    nombre="";
    apaterno="";
    amaterno="";
    folio=null;
  constructor(private con: ConexionApiService,
    private cookie: CookieService,
    private router: Router) { }

  ngOnInit(): void {
    if(!this.cookie.check("impe")){
      this.router.navigate(['/']);
    }
    this.impeRH=this.cookie.get("impe");
      this.nombre=this.cookie.get("nombre");
      this.apaterno=this.cookie.get("apaterno");
      this.amaterno=this.cookie.get("amaterno");
      this.obtenerDatos();
  }
  obtenerDatos(){
    this.con.obtenerRegistradosComision().subscribe(
      result=>{
        if(result[0]!=null){
          this.registrados=result;
        }
      }
    );
  }

  guardarDatos(){
    this.vato.impeUsuario=parseInt(this.impeRH);
    this.vato.impe=this.impe;

    this.con.altaRegistroComision(this.vato).subscribe(
      datos=>{
        if(datos[0]!=null){
          MensajeRegistroCompletoComision(datos[0].id);
          this.folio="CPCHJ"+datos[0].id;
          this.registrados=null;
          $('#tablilla').DataTable().clear();
          $('#tablilla').DataTable().destroy();
          this.obtenerDatos();
        }else
        if(datos['resultado']!='OK'){
          alert(datos['mensaje']);
        }
      }
    );

  }

  mensaje(){
    var a=confirm("Se eliminaran los datos que no hayan sido guardados, ¿desea continuar?")
    if(a){
      window.location.reload();
    }
  }

  cerrarSesion(){
    this.cookie.deleteAll();
    this.router.navigate(['/login']);
  }
  check($event){
    this.con.obtenerDatos(this.impe).subscribe(
      result=>{
        if(result[0] != null){
          this.resultao=result;

          this.resultao.forEach(a => {
            this.vato.nombre=a.nombre;
            this.vato.apaterno=a.apellido_paterno;
            this.vato.amaterno=a.apellido_materno;
            this.vato.puesto=a.puesto;

          });

      }
    }
    );
  }

  imprimir(){
    window.print();
  }

  actualizar(){
    this.con.actualizarComision(this.vato.carpeta,this.vato.expediente,this.vato.asunto,this.vato.fecha,this.vato.impe).subscribe(
      datos=>{
        if(datos['resultado']=="OK"){
          alert("Actualizado correctamente");
          this.registrados=null;
          $('#tablilla').DataTable().clear();
          $('#tablilla').DataTable().destroy();
          this.obtenerDatos();
        }else{
          alert("Ocurrio un error al actualizar")
        }
      }
    );
  }

  editar(a){
    this.impe=a.impe;
    this.vato.impe=a.impe;
    this.vato.nombre=a.nombre;
    this.vato.apaterno=a.apellidoP;
    this.vato.amaterno=a.apellidoM;
    this.vato.puesto=a.puesto;
    this.vato.carpeta=a.carpeta;
    this.vato.expediente=a.numExpediente;
    this.vato.fecha=a.fecha;
    this.vato.asunto=a.asunto;
    this.edit=true;
  }

  iniciar() {
    $('#tablilla').DataTable({
      dom: 'Bfrtip',
      "autoWidth": true,
      retrieve:true,
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons: ['copy', 'excel'],
        },
      ],
    });

  }

}
