<div class="jumbotron">
<div class="row">
  <div class="col-lg-12">
    <h1>Enseñanza y formación continua</h1>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 mb-5">
    <div class="form-row">
      <div class="form-group mx-2">
        <label for="materia">Nombre materia</label>
        <input type="text" [(ngModel)]="nombreMateria" id="materia" class="form-control">
      </div>
      <div class="form-group mx-2">
        <label for="dep">Departamento</label>
        <select name="" id="dep" class="form-control" [(ngModel)]="depa">
          <option value="POLICÍA">Policía</option>
          <option value="BOMBERO">Bomberos</option>
        </select>
      </div>



    </div>
    <button class="btn btn-success" (click)="guardarMateria()">Guardar</button>
  </div>
  <div class="col-lg-4 offset-lg-4" style="overflow-y: scroll; display: block; max-height: 200px;">
    <table class="w-100 table table-striped table-bordered "  style="border: 1px solid; max-height: 500px; overflow-y: scroll;">
      <thead class="thead-dark">
        <tr>
          <th>Nombre materia</th>
          <th>Departamento</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="materias==null">
          <td colspan="2"><p class="text-center">No hay materias añadidas</p></td>
        </tr>
        <tr *ngFor="let a of materias">
          <td>
            {{a.nombre}}
          </td>
          <td>
            {{a.depa}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

<div class="row mt-5">
  <div class="col-lg-4 mb-5 ">
    <div class="form-row">
      <div class="form-group mx-2">
        <label for="materia">Nombre grupo</label>
        <input type="text" [(ngModel)]="nombreGrupo" id="nombregrupo" class="form-control">
      </div>
      <div class="form-group mx-2">
        <label for="dep">Departamento</label>
        <select name="" id="deps" class="form-control" [(ngModel)]="depa2">
          <option value="POLICÍA">Policía</option>
          <option value="BOMBERO">Bomberos</option>
        </select>
      </div>



    </div>
    <button class="btn btn-success" (click)="guardarGrupo()">Guardar</button>
  </div>
  <div class="col-lg-4 offset-lg-4" style="overflow-y: scroll; display: block; max-height: 200px;">
    <table class="w-100 table table-striped table-bordered "  style="border: 1px solid; max-height: 500px; overflow-y: scroll;">
      <thead class="thead-dark">
        <tr>
          <th>Nombre grupo</th>
          <th>Departamento</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="grupos==null">
          <td colspan="2"><p class="text-center">No hay grupos añadidos</p></td>
        </tr>
        <tr *ngFor="let a of grupos">
          <td>
            {{a.grupo}}
          </td>
          <td>
            {{a.depa}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

<div class="row ">
  <div class="col-lg-12">
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Policía</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Bomberos</a>
      </li>

    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><div  class="table-responsive text-nowrap">
        <table style="display: block; overflow-x: scroll;overflow-y: scroll; max-height:500px ;" class="table table-hover  " id="tablilla" >
    <thead class="thead-dark">
      <tr>
        <th class="text-center">ID</th>
        <th class="text-center">
          Folio RH
        </th>
        <th class="text-center">
          Folio Comision
        </th>
        <th class="text-center">impe</th>
        <th class="text-center">nombre</th>
        <th class="text-center">sexo</th>
        <th class="text-center">puesto</th>
        <th class="text-center">Subdirección</th>
        <th class="text-center">departamento</th>
        <th class="text-center">nivel academico</th>
        <th class="text-center">reconocimientos</th>
        <th class="text-center">constancias</th>
        <th class="text-center">arrestos</th>
        <th class="text-center">antigüedad</th>
        <th class="text-center">puntaje</th>
        <th class="text-center">llego a escuela de policía</th>
        <th class="text-center">persona dio alta RH</th>
        <th class="text-center">Tiene carpeta abierta</th>
        <th class="text-center">persona dio alta Comision</th>
        <th class="text-center">persona edito RH</th>
        <th class="text-center">Fecha edicion</th>
        <th class="text-center">Selecciona Grupo</th>
        <th class="text-center">Grupo</th>
      </tr>
    </thead>
       <tbody>
        <tr *ngFor="let a of datos; let i=index; let lastcall=last;" [attr.class]="a.carpeta ==  'Si' ? 'table-danger' : null" data-toggle="modal" [attr.data-target]="a.carpeta ==  'Si' ? '#exampleModal' : null" (click)="poner(a.carpeta,a.numExpediente,a.asunto,a.fecha,a)">
          <td class="text-center" >{{a.id}}</td>
          <td class="text-center" >PPAGSI{{a.id}}</td>
          <td class="text-center" ><span *ngIf="a.idComision != null">CPCHJ{{a.idComision}}</span> </td>
          <td class="text-center">{{a.impe}}</td>
          <td class="text-center">{{a.nombre}}</td>
          <td class="text-center">{{a.sexo}}</td>
          <td class="text-center">{{a.puesto}}</td>
          <td class="text-center">{{a.subdireccion}}</td>
          <td class="text-center">{{a.depa}}</td>
          <td class="text-center">{{a.nivelAcademico}}</td>
          <td class="text-center">{{a.reconocimientos}}</td>
          <td class="text-center">{{a.constancias}}</td>
          <td class="text-center">{{a.arrestos}}</td>
          <td class="text-center">{{a.antiguedad}}</td>
          <td class="text-center">{{a.puntuaje}} puntos</td>
          <td class="text-center"> <input type="checkbox" id="{{a.id}}" [attr.value]="a.llegoEscuela == 1 ? '1' : '0'" [attr.checked]="a.llegoEscuela == 1 ? true : null" (change)="cambiar(a.id)"> <span class="d-none" *ngIf="a.llegoEscuela == 1">SI</span> <span class="d-none" *ngIf="a.llegoEscuela == 0">NO</span> </td>
          <td class="text-center">{{a.nombreUsuarioAlta}}</td>
          <td class="text-center"><span *ngIf="a.carpeta != null">{{a.carpeta}}</span> <span *ngIf="a.carpeta == null">No fue registrado en la comisión</span> </td>
          <td class="text-center">{{a.nombreUsuarioAltaComision}}</td>
          <td class="text-center">{{a.nombreUsuarioActualizo}}</td>
          <td class="text-center">{{a.fechaUpdate}}</td>
          <td class="text-center"><select name="grupo" id="grupo" class="form-control" style="width: 180px;" (change)="actualizarGrupo($event.srcElement.value,a.id)">
            <option value="0">seleccione</option>
            <option *ngFor="let b of grupos" [value]="b.id" [attr.selected]="a.grupo == b.id ? true : null">{{b.grupo}}</option>
          </select></td>
          <td class="text-center">{{a.grupon}}</td>
          <span *ngIf="lastcall">{{iniciar()}} </span>
        </tr>

       </tbody>

        </table>
      </div></div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><div  class="table-responsive text-nowrap">
        <table style="display: block; overflow-x: scroll;overflow-y: scroll; max-height:500px ;" class="table table-hover  " id="tablilla2" >
    <thead class="thead-dark">
      <tr>
        <th class="text-center">ID</th>
        <th class="text-center">
          Folio RH
        </th>
        <th class="text-center">
          Folio Comision
        </th>
        <th class="text-center">impe</th>
        <th class="text-center">nombre</th>
        <th class="text-center">puesto</th>
        <th class="text-center">Subdirección</th>
        <th class="text-center">departamento</th>
        <th class="text-center">nivel academico</th>
        <th class="text-center">reconocimientos</th>
        <th class="text-center">constancias</th>
        <th class="text-center">arrestos</th>
        <th class="text-center">antigüedad</th>
        <th class="text-center">puntaje</th>
        <th class="text-center">llego a escuela de policía</th>
        <th class="text-center">persona dio alta RH</th>
        <th class="text-center">Tiene carpeta abierta</th>
        <th class="text-center">persona dio alta Comision</th>

      </tr>
    </thead>
       <tbody>
        <tr *ngFor="let a of datos2; let i=index2; let lastcall2=last;" [attr.class]="a.carpeta ==  'Si' ? 'table-danger' : null" data-toggle="modal" [attr.data-target]="a.carpeta ==  'Si' ? '#exampleModal' : null" (click)="poner(a.carpeta,a.numExpediente,a.asunto,a.fecha,a)">
          <td class="text-center" >{{a.id}}</td>
          <td class="text-center" >PPAGSI{{a.id}}</td>
          <td class="text-center" ><span *ngIf="a.idComision != null">CPCHJ{{a.idComision}}</span> </td>
          <td class="text-center">{{a.impe}}</td>
          <td class="text-center">{{a.nombre}}</td>
          <td class="text-center">{{a.puesto}}</td>
          <td class="text-center">{{a.subdireccion}}</td>
          <td class="text-center">{{a.depa}}</td>
          <td class="text-center">{{a.nivelAcademico}}</td>
          <td class="text-center">{{a.reconocimientos}}</td>
          <td class="text-center">{{a.constancias}}</td>
          <td class="text-center">{{a.arrestos}}</td>
          <td class="text-center">{{a.antiguedad}}</td>
          <td class="text-center">{{a.puntuaje}} puntos</td>
          <td class="text-center"> <input type="checkbox" id="{{a.id}}" [attr.value]="a.llegoEscuela == 1 ? '1' : '0'" [attr.checked]="a.llegoEscuela == 1 ? true : null" (change)="cambiar(a.id)"> <span class="d-none" *ngIf="a.llegoEscuela == 1">SI</span> <span class="d-none" *ngIf="a.llegoEscuela == 0">NO</span> </td>
          <td class="text-center">{{a.nombreUsuarioAlta}}</td>
          <td class="text-center"><span *ngIf="a.carpeta != null">{{a.carpeta}}</span> <span *ngIf="a.carpeta == null">No fue registrado en la comisión</span> </td>
          <td class="text-center">{{a.nombreUsuarioAltaComision}}</td>
         
          <span *ngIf="lastcall2">{{iniciar2()}} </span>
        </tr>

       </tbody>

        </table>
      </div></div>

    </div>
  </div>


</div>

<div class="row mt-5" *ngIf="persona != null">
  <div class="row" *ngIf="persona.carpeta == 'No' || persona.carpeta == null">
    <div class="col-lg-5" >
      <h3>{{persona.nombre}}</h3>
      <h6>{{persona.puesto}}</h6>
      <h5>{{persona.puntuaje}} puntos sin examenes</h5>
    </div>
    <div class="col-lg-7" *ngIf="califas!=null">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th class="text-center">Nombre Materia</th>
            <th class="text-center">Calificacion</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of califas">
            <td class="text-center">{{a.nombre}}</td>
            <td class="text-center">{{a.calificacion}}</td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td class="text-right">Total:</td>
            <td class="text-center">{{calificacion}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

</div>
<div class="row mt-5">
  <div class="col-lg-12 mt-5">
    <h3>Calificaciones general</h3>
    <div  class="table-responsive text-nowrap">
    <table style="display: block; overflow-x: scroll;overflow-y: scroll; max-height:500px ;" class="table table-hover  " id="tablilla3" >
      <thead class="thead-dark">
        <tr>
          <th class="text-center">impe</th>
          <th class="text-center">nombre</th>
          <th class="text-center">puesto</th>
          <th class="text-center">grupo</th>
          <th class="text-center">subdirección</th>
          <th class="text-center" *ngFor="let a of materias;">{{a.nombre}} </th>
          <th class="text-center">fecha</th>
         
        </tr>
      </thead>
         <tbody>
          <tr *ngFor="let a of calificaciones; let i=index3; let lastcall3=last;" >
            <td class="text-center">{{a.impe}}</td>
            <td class="text-center">{{a.nombre}}</td>
            <td class="text-center">{{a.puesto}}</td> 
            <td class="text-center">{{a.grupo}}</td> 
            <td class="text-center">{{a.subdireccion}}</td> 
            <td class="text-center">{{a.materia1}}</td> 
            <td class="text-center">{{a.materia2}}</td> 
            <td class="text-center">{{a.materia3}}</td> 
            <td class="text-center">{{a.materia4}}</td> 
            <td class="text-center">{{a.materia5}}</td> 
            <td class="text-center">{{a.materia6}}</td> 
            <td class="text-center">{{a.materia7}}</td> 
            <td class="text-center">{{a.materia8}}</td> 
            <td class="text-center">{{a.materia9}}</td> 
            <td class="text-center">{{a.fecha}}</td> 
            <span *ngIf="lastcall3">{{iniciar3()}} </span>
          </tr>
  
         </tbody>
  
          </table>
        </div>
  </div>
  

</div>
</div>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Datos de carpeta abierta en comisión</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [innerHtml]="carpeta">

      </div>
      <div class="modal-footer">
        <button type="button" id="botonCerrar" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>

      </div>
    </div>
  </div>
</div>
