import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ConexionApiService } from "./conexion-api.service";
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RHComponent } from './rh/rh.component';
import { RhBComponent } from './rh-b/rh-b.component';
import { ComisionComponent } from './comision/comision.component';
import { EyfcComponent } from './eyfc/eyfc.component';
import { CalificacionesComponent } from './calificaciones/calificaciones.component';
import { AltaCalificacionesComponent } from './alta-calificaciones/alta-calificaciones.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RHComponent,
    RhBComponent,
    ComisionComponent,
    EyfcComponent,
    CalificacionesComponent,
    AltaCalificacionesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule

  ],
  providers: [ConexionApiService,
    CookieService,
    {provide:LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
