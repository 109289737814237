<div class="jumbotron no-print pt-2 mb-0 pb-5">
  <div class="row">
    <div class="col-lg-3 text-right offset-lg-9">
      <h5 (click)="cerrarSesion()" style="cursor: pointer;"><i class="fas fa-power-off"></i>Cerrar sesión</h5>
    </div>
  </div>

  <div class="no-print mt-0 p-0">
    <div class="row">

      <div class="col-lg-5 offset-md-7  mt-2 mb-4">
        <img src="assets/logoGob2.png" width="100%" alt="" class="no-print">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 offset-lg-10">
        <h3><b>Folio: <span style="color:red">{{folio}}</span></b></h3>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-3">
        <label for="impe">IMPE</label>
        <input type="number"  [(ngModel)]="impe" name="impe" class="form-control" id="impe" (focusout)="check($event)" (keyup)="check($event)">
      </div>
      <div class="form-group col-md-3">
        <label for="apaterno">Apellido paterno</label>
        <input type="text" [(ngModel)]="vato.apaterno" class="form-control" id="apaterno">
      </div>
      <div class="form-group col-md-3">
        <label for="amaterno">Apellido Materno</label>
        <input type="text" [(ngModel)]="vato.amaterno" class="form-control" id="amaterno">
      </div>
      <div class="form-group col-md-3">
        <label for="nombre">Nombre</label>
        <input type="text" [(ngModel)]="vato.nombre" class="form-control" id="nombre">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-3">
        <label for="puesto">Puesto</label>
        <input type="text" [(ngModel)]="vato.puesto" class="form-control" id="puesto">
      </div>
      <div class="form-group col-md-3">
        <label for="carpeta">Tiene carpeta abierta</label>
        <select name="" id="carpeta" class="form-control" [(ngModel)]="vato.carpeta">
          <option value="SELECCIONE">Seleccione..</option>
          <option value="1">Si</option>
          <option value="0">No</option>
        </select>
      </div>



    </div>
    <div class="form-row" *ngIf="vato.carpeta=='1'">
      <div class="form-group col-md-3">
        <label for="expediente">Número expediente</label>
        <input type="text" name="expediente" [(ngModel)]="vato.expediente" class="form-control">
      </div>
      <div class="form-group col-md-3">
        <label for="asunto">Asunto</label>
        <textarea name="asunto" id="" [(ngModel)]="vato.asunto" class="form-control" cols="30" rows="5"></textarea>
      </div>
      <div class="form-group col-md-3">
        <label for="fecha">Fecha</label>
        <input type="date" name="fecha" [(ngModel)]="vato.fecha" class="form-control">
      </div>
    </div>
    <div class="row mb-4 mt-4">
      <div class="col-lg-12">
        <h6>La información aquí descrita es  responsabilidad de quien captura y de quién recibe, ofreciendo así la veracidad de la misma para el proceso de Promoción y Ascenso de Grado Superior Inmediato 1/2020</h6>
      </div>
     </div>
      <br>
      <button type="submit" class="btn btn-primary no-print" *ngIf="folio==null && !edit" (click)="guardarDatos()">Registrar</button>
      <button type="submit" class="btn btn-primary no-print" *ngIf="edit" (click)="actualizar()">Actualizar</button>
      &nbsp;<button class="btn btn-primary no-print" (click)="mensaje()">vaciar datos</button>

      <div  class="text-nowrap mt-5">
        <table style="overflow-x: scroll;overflow-y: scroll; max-height:500px ;" class="table table-hover  " id="tablilla" >
    <thead class="thead-dark">
      <tr>
        <th class="text-center">
          Folio Comision
        </th>
        <th class="text-center">impe</th>
        <th class="text-center">nombre</th>
        <th class="text-center">puesto</th>
        <th class="text-center">subdirección</th>
        <th class="text-center">puntaje</th>
        <th class="text-center">Tiene carpeta abierta</th>
        <th class="text-center">persona dio alta Comision</th>
        <th class="text-center">Editar</th>
      </tr>
    </thead>
       <tbody>
        <tr *ngFor="let a of registrados; let i=index2; let lastcall2=last;" >
          <td class="text-center" >CPCHJ{{a.id}}</td>
          <td class="text-center">{{a.impe}}</td>
          <td class="text-center">{{a.nombre}} {{a.apellidoP}} {{a.apellidoM}}</td>
          <td class="text-center">{{a.puesto}}</td>
          <td class="text-center">{{a.subdireccion}}</td>
          <td class="text-center">{{a.puntuaje}}</td>
          <td class="text-center"><span *ngIf="a.carpeta == 1">si</span><span *ngIf="a.carpeta == 0">no</span></td>
          <td class="text-center">{{a.nombreUsuarioAltaComision}}</td>
          <td class="text-center"><i class="fas fa-pen" style="cursor: pointer;" (click)="editar(a)"></i></td>
          <span *ngIf="lastcall2">{{iniciar()}}</span>
        </tr>

       </tbody>

        </table>
      </div>
  </div>
  </div>


  <div class="imprimir print-only" id="imprimir">
    <div class="container">
     <div class="row">
      <div class="col-lg-4 offset-md-8">
        <img src="assets/logoGob2.png" width="100%" alt="" class="print-">
      </div>
      <div class="col-lg-4 mt-4 offset-md-8">
        <p>Folio: <span style="color: red;"><b>{{folio}}</b></span></p>
      </div>
     </div>
     <div class="row">
       <div class="col-lg-12">
        <h3 style="text-transform: uppercase;">{{vato.apaterno}} {{vato.amaterno}} {{vato.nombre}}</h3>
       </div>
  <div class="col-lg-12">
    <h4>{{vato.puesto}}</h4>
  </div>

     </div>
     <div class="row my-5">
      <div class="col-lg-12">
        <p class="text-justify" *ngIf="vato.carpeta=='1'"><b>La Comisión Profesional de Carrera, Honor y Justicia hace constar que NO  puede participar en el proceso de Promoción y Ascenso de Grado Superior Inmediato 1/2020 debido a que presenta expediente abierta ({{vato.expediente}}, {{vato.asunto}}, {{vato.fecha}})</b></p>
        <p class="text-justify" *ngIf="vato.carpeta=='0'"><b>La Comisión Profesional de Carrera, Honor y Justicia hace constar que SÍ se puede participar en el proceso de Promoción y Ascenso de Grado Superior Inmediato 1/2020 al no presentar expediente abiertos.</b></p>
      </div>
     </div>
     <div class="row mb-5">
      <div class="col-lg-12">
        <h6>La información aquí descrita es  responsabilidad de quien captura y de quién recibe, ofreciendo así la veracidad de la misma para el proceso de Promoción y Ascenso de Grado Superior Inmediato 1/2020</h6>
      </div>
     </div>


     <table style="width: 100%;" class="mt-5">
      <tr>
        <td class="text-center">_______________________________________________</td>
        <td colspan="3">&nbsp;</td>
        <td class="text-center">_______________________________________________</td>
      </tr>
      <tr>
        <td class="text-center"><h6 style="text-transform: uppercase;">{{vato.nombre}} {{vato.apaterno}} {{vato.amaterno}}</h6></td>
        <td colspan="3">&nbsp;</td>
        <td class="text-center"> <h6 style="text-transform: uppercase;">{{nombre}} {{apaterno}} {{amaterno}}</h6></td>
      </tr>
      <tr>
        <td class="text-center"><h6 style="text-transform: uppercase;">{{vato.puesto}}</h6></td>
        <td colspan="3">&nbsp;</td>
        <td class="text-center"> <h6 style="text-transform: uppercase;">Comisión Profesional de Carrera, Honor y Justicia</h6></td>
      </tr>
     </table>
    </div>
  </div>
