<div class="jumbotron pb-0">
  <div class="row p-0 mt-0">
    <div class="col-lg-3 text-right offset-lg-9">
      <h5 (click)="cerrarSesion()" style="cursor: pointer;"><i class="fas fa-power-off"></i>Cerrar sesión</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-5 offset-md-7  mt-2 mb-4">
      <img src="assets/logoGob2.png" width="100%" alt="" class="no-print">
    </div>
  </div>
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-12">
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="depa">Departamento</label>
            <select name="depa"  [(ngModel)]="depa" class="form-control" id="depa" (click)="obtenerMaterias()">
              <option value="1">Seleccione</option>
              <option value="POLICÍA">Policía</option>
              <option value="BOMBERO">Bombero</option>
            </select>
          </div>
          <div class="form-group col-lg-6" *ngIf="materias!=null" >
            <label for="materia">Materia</label>
            <select name="materia" class="form-control" [(ngModel)]="materia" (change)="obtenerCalifas()" id="materia">
              <option value="0">Seleccione</option>
              <option  *ngFor="let a of materias" value="{{a.id}}">{{a.nombre}}</option>

            </select>
          </div>
        </div>

      </div>


    </div>

    <div class="row mt-5" >
      <div  class="col-lg-12">
        <table style="display: block; overflow-x: scroll;overflow-y: scroll; max-height:500px; width: 100%;" class="table " id="tablilla" >
          <thead class="thead-dark">
            <tr>
              <th>id</th>
              <th>impe</th>
              <th>Nombre</th>
              <th>puesto</th>
              <th>Calificación</th>
            </tr>
          </thead>
             <tbody>
              <ng-container *ngFor="let a of personas; let i=index; let lastcall=last">
                <tr  *ngIf="a.depa==depa">
                  <td >{{a.id}}</td>
                  <td >{{a.impe}}</td>
                  <td >{{a.nombre}}</td>
                  <td >{{a.puesto}}</td>
                  <td ><input type="number" (keydown)="validar(a.id)" (keypress)="validar(a.id)" [attr.name]="a.id" [attr.id]="a.id"  [attr.value]="a.calificacion" class="form-control" (keyup)="onKeyUp($event,a.id,a.impe)"></td>
                </tr>
                <span *ngIf="lastcall">{{iniciar()}} </span>
              </ng-container>

             </tbody>

              </table>
      </div>
    </div>
  </div>
</div>




<div class="cargando" *ngIf="cargando">
  <img src="assets/loading.gif" width="100px" height="100px" alt="">
</div>
