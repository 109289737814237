import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { promise } from 'protractor';

declare var $: any;
@Component({
  selector: 'app-alta-calificaciones',
  templateUrl: './alta-calificaciones.component.html',
  styleUrls: ['./alta-calificaciones.component.css'],
})
export class AltaCalificacionesComponent implements OnInit {
  materias = null;
  depa = '1';
  personas = null;
  materia = '0';
  tabla;
  cargando=false;
   typingTimer;  //timer identifier
 doneTypingInterval = 500;
  constructor(
    private con: ConexionApiService,
    private cookie: CookieService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(!this.cookie.check("impe")){
      this.router.navigate(['/login']);
    }
    this.iniciar();
  }
  cerrarSesion(){
    this.cookie.deleteAll();
    this.router.navigate(['/login']);
  }
  obtenerMaterias() {
    if (this.depa != '1') {
      this.cargando=true;
      this.con.obtenerMateriasDepa(this.depa).subscribe((result) => {
        if (result[0] != null) {
          this.materias = result;
          this.materia = '0';
          this.personas = null;
          this.cargando=false;
        }else{
          this.materias=null;
          this.cargando=false;
        }
      });
    } else {
      this.materias = null;
    }
  }

  ponerDatatable() {
    $('#tablilla').DataTable({
      dom: 'Bfrtip',
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      select: true,
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons: ['copy', 'excel', 'csv'],
        },
      ],
    });
  }

  async obtenerCalifas() {

    this.cargando=true;
    this.personas=null;
    $('#tablilla').DataTable().clear();
    $('#tablilla').DataTable().destroy();


     var a= await this.ponerCalifas();
     this.cargando=false;
  }

  ponerCalifas() {
    return new Promise((resolve) => {
      this.con.obtenerCalifas(this.materia).subscribe((result) => {
        if (result[0] != null) {
          this.personas = null;
          this.personas = result;

          resolve(true);
        } else {

          this.personas = null;
          resolve(false);
        }
      });
    });
  }

  onKeyUp($event,id,impe){
    this.validar(id);
    clearTimeout(this.typingTimer);
    if ($('#in').val) {
        this.typingTimer = setTimeout(()=>{
          this.guardarCalifas(id,impe);
        }, this.doneTypingInterval);
    }

  }

  validar(id){
    var a=(<HTMLInputElement>document.getElementById(id)).value;
    if(parseInt(a)>10){
      (<HTMLInputElement>document.getElementById(id)).value=(10).toString();
      return false;
    }
    if(a==null || a==""){
      (<HTMLInputElement>document.getElementById(id)).value=(0).toString();
      return false;
    }
  }
   guardarCalifas(id,impe){
    var a=(<HTMLInputElement>document.getElementById(id)).value;
    this.con.updateCalifas(impe,this.materia,a).subscribe(
      datos=>{
        if(datos['resultado']=='OK'){
          console.log(datos['mensaje']);
        }else{
          console.log(datos['mensaje']);
        }
      }
    );
  }


  iniciar() {
    $('#tablilla').DataTable({
      dom: 'Bfrtip',
      "autoWidth": true,
      retrieve:true,
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons:false,
        },
      ],
    });

  }
  }

