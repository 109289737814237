import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare function MensajeRegistroCompleto(x):any;
declare function MensajeActualizadoCompleto():any;

declare var $: any;
@Component({
  selector: 'app-rh',
  templateUrl: './rh.component.html',
  styleUrls: ['./rh.component.css']
})
export class RHComponent implements OnInit {
impe=null;
edit=false;
vato={
  impe:null,
  nombre:"",
  apaterno:"",
  amaterno:"",
  puesto:"",
  nivelAcademico:"",
  nivelAcademico2:"",
  capacitaciones:0,
  arrestos:0,
  antiguedad:0,
  capacitaciones2:'',
  arrestos2:'',
  antiguedad2:'',
  reconocimientos:"",
  puntuaje:null,
  impeUsuario:null,
  depa:"POLICÍA",
  depa2:""
}
altoImpacto="";
poliMes="";
asignation="";
altoImpactoTot=null;
asignacionTot=null;
poliMesTot=null;
puntosRecAlto=0;
puntosRecPoli=0;
resultao=null;
resultao2=null;
totalPuntos=0;
totalAcademico=0;
totalConstancias=0;
totalArrestos=0;
totalAntiguedad=0;
recono1=0;
recono2=0;
diplomado="";
folio=null;
registrados=null;
  constructor(private con: ConexionApiService,
    private cookie: CookieService,
    private router: Router) { }
    impeRH="";
    nombre="";
    apaterno="";
    amaterno="";
  ngOnInit(): void {
    if(!this.cookie.check("impe")){
      this.router.navigate(['/']);
    }
      this.totalInfinito();
      this.impeRH=this.cookie.get("impe");
      this.nombre=this.cookie.get("nombre");
      this.apaterno=this.cookie.get("apaterno");
      this.amaterno=this.cookie.get("amaterno");
      this.obtenerDatos();
  }

  actualizar(){
    var extra="";
    var c=<HTMLInputElement>document.getElementById("extra1");
    if(c.checked){
      extra=" tiene el diplomado para mando o mando policial - ";
    }
    this.vato.puntuaje=this.totalPuntos;
    this.vato.impeUsuario=parseInt(this.impeRH);
    this.vato.impe=this.impe;
    this.vato.nivelAcademico2=this.vato.nivelAcademico+" - Puntos: "+this.totalAcademico;
    this.vato.arrestos2=this.vato.arrestos+" arrestos - Puntos: "+this.totalArrestos;
    this.vato.reconocimientos=""+(this.altoImpacto+" "+this.poliMes+" "+this.asignation+" puntos en total: "+this.checkPuntosRec());
    this.vato.capacitaciones2=this.vato.capacitaciones+" constancias - "+extra+"Puntos: "+this.totalConstancias;
    this.vato.antiguedad2=this.vato.antiguedad+" años - Puntos: "+this.totalAntiguedad;

    this.vato.puntuaje=this.totalPuntos;
    console.log(this.vato);
    this.con.actualizaRegistro(this.vato).subscribe(
      datos=>{
        if(datos[0]!=null){
          MensajeActualizadoCompleto();
         
        }else
        if(datos['resultado']!='OK'){
          alert(datos['mensaje']);
        }
      }
    );

  }

  obtenerDatos(){
    this.con.obtenerRegistradosRhPolicias().subscribe(
      result=>{
        if(result[0]!=null){
          this.registrados=result;
        }
      }
    );
  }
  
  iniciar() {
    $('#tablilla').DataTable({
      dom: 'Blfrtip',
      "scrollY":"500px",
      "scrollCollapse": true,
      retrieve:true,
      lengthMenu: [
        [10, 50, 100, -1],
        [10, 50, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons:['pdf'],
        },
      ],
    });

  }

  editar(a){
    this.impe=a.impe;
    this.vato.impe=a.impe;
    this.vato.nombre=a.nombre;
    this.vato.apaterno=a.apaterno;
    this.vato.amaterno=a.amaterno;
    this.vato.puesto=a.puesto;
    this.vato.depa2=a.subdireccion;
    var str=a.nivelAcademico.split("-");
    this.vato.nivelAcademico=str[0].substring(0,str[0].length -1);
    str=a.antiguedad.split(" ");
    this.vato.antiguedad=str[0];
    str=a.arrestos.split(" ");
    this.vato.arrestos=str[0];
    str=a.constancias.split(" ");
    this.vato.capacitaciones=str[0];
    str=a.constancias.split("-");
    
    if(str[1].includes("diplomado")){
      var c=<HTMLInputElement>document.getElementById("extra1");
      c.checked=true;
      this.diplomado="Diplomado para mando o mando policial 3 puntos";
    }else{
      var c=<HTMLInputElement>document.getElementById("extra1");
      c.checked=false;
      this.totalConstancias=this.vato.capacitaciones;
      this.diplomado="";
    }
    str=a.reconocimientos;
    if(str.includes("Alto impacto")){
      if(str.includes("policía del mes")){
        str=str.split(" ");
        this.poliMesTot=parseInt(str[6]);
        
        var c=<HTMLInputElement>document.getElementById("polic");
      c.checked=true;
      this.altoImpactoTot=parseInt(str[2]);
       c=<HTMLInputElement>document.getElementById("altoImp");
      c.checked=true;
      (<HTMLInputElement>document.getElementById("polimes")).disabled=false;
      this.poliMes="";
      (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=false;
      this.altoImpacto="";
      }else{
        str=str.split(" ");
        this.poliMesTot=null;
        var c=<HTMLInputElement>document.getElementById("polic");
        c.checked=false;
        (<HTMLInputElement>document.getElementById("polimes")).disabled=true;
      this.poliMes="";
      this.altoImpactoTot=parseInt(str[2]);
      c=<HTMLInputElement>document.getElementById("altoImp");
       c.checked=true;
       (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=false;
      this.altoImpacto="";
      }
    }else if(str.includes("policía del mes")){
     
      str=str.split(" ");
      this.poliMesTot=parseInt(str[4]);
     
      var c=<HTMLInputElement>document.getElementById("polic");
    c.checked=true;
    this.altoImpactoTot=null;
     c=<HTMLInputElement>document.getElementById("altoImp");
    c.checked=false;
    (<HTMLInputElement>document.getElementById("polimes")).disabled=false;
    this.poliMes="";
    (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=true;
    this.altoImpacto="";
    }else{
      this.poliMesTot=null;
      
      var c=<HTMLInputElement>document.getElementById("polic");
    c.checked=false;
    this.altoImpactoTot=null;
     c=<HTMLInputElement>document.getElementById("altoImp");
    c.checked=false;
    (<HTMLInputElement>document.getElementById("polimes")).disabled=true;
    this.poliMes="";
    (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=true;
    this.altoImpacto="";
    }
    this.ponerTotAlto();
    this.ponerTotPoli();
    this.edit=true;
  }


  extra(){
    var c=<HTMLInputElement>document.getElementById("extra1");
    if(c.checked){
      this.totalConstancias+=3;
      this.diplomado="Diplomado para mando o mando policial 3 puntos";
    }else{
      this.totalConstancias=this.vato.capacitaciones;
      this.diplomado="";
    }
  }


  check($event){
    this.con.obtenerDatos(this.impe).subscribe(
      result=>{
        if(result[0] != null){
          this.resultao=result;
          this.resultao.forEach(a => {
            this.vato.nombre=a.nombre;
            this.vato.apaterno=a.apellido_paterno;
            this.vato.amaterno=a.apellido_materno;
            this.vato.puesto=a.puesto;
            this.vato.nivelAcademico=a.nivelAcademico;
            this.vato.antiguedad=a.antiguedad;
          });

      }else{
        this.vato={
          impe:null,
          nombre:"",
          apaterno:"",
          amaterno:"",
          puesto:"",
          nivelAcademico:"",
          nivelAcademico2:"",
          capacitaciones:0,
          arrestos:0,
          antiguedad:0,
          capacitaciones2:'',
          arrestos2:'',
          antiguedad2:'',
          reconocimientos:"",
          puntuaje:null,
          impeUsuario:null,
          depa:"POLICÍA",
          depa2:""
        }
      }
    }
    );
  }

  asignacion(){
    var a=<HTMLInputElement>document.getElementById("altoImp");
    var b=<HTMLInputElement>document.getElementById("polic");
    if(a.checked || b.checked){
      alert("una persona puede tener alto impacto y policia del mes, pero no puede tener asignación especial y viceversa");
      var c=<HTMLInputElement>document.getElementById("asig");
      c.checked=false;
    }else{
      var c=<HTMLInputElement>document.getElementById("asig");
      if(c.checked){
        this.asignacionTot=10;
        this.asignation="Asignacion Especial";
      }else{
        this.asignacionTot=0;
        this.asignation="";
      }

    }
  }

  imprimir(){

     window.print();
  }
  cerrarSesion(){
    this.cookie.deleteAll();
    this.router.navigate(['/login']);
  }

  guardarDatos(){
    var extra="";
    var c=<HTMLInputElement>document.getElementById("extra1");
    if(c.checked){
      extra=" tiene el diplomado para mando o mando policial - ";
    }
    this.vato.puntuaje=this.totalPuntos;
    this.vato.impeUsuario=parseInt(this.impeRH);
    this.vato.impe=this.impe;
    this.vato.nivelAcademico2=this.vato.nivelAcademico+" - Puntos: "+this.totalAcademico;
    this.vato.arrestos2=this.vato.arrestos+" arrestos - Puntos: "+this.totalArrestos;
    this.vato.reconocimientos=""+(this.altoImpacto+" "+this.poliMes+" "+this.asignation+" puntos en total: "+this.checkPuntosRec());
    this.vato.capacitaciones2=this.vato.capacitaciones+" constancias - "+extra+"Puntos: "+this.totalConstancias;
    this.vato.antiguedad2=this.vato.antiguedad+" años - Puntos: "+this.totalAntiguedad;

    this.vato.puntuaje=this.totalPuntos;

    this.con.altaRegistro(this.vato).subscribe(
      datos=>{
        if(datos[0]!=null){
          MensajeRegistroCompleto(datos[0].id);
          this.folio="PPAGSI"+datos[0].id;
        }else
        if(datos['resultado']!='OK'){
          alert(datos['mensaje']);
        }
      }
    );

  }
  mensaje(){
    var a=confirm("Se eliminaran los datos que no hayan sido guardados, ¿desea continuar?")
    if(a){
      window.location.reload();
    }

  }
  async totalInfinito(){
    this.totalPuntos=this.totalArrestos+this.totalAcademico+this.totalConstancias+this.totalAntiguedad+this.resultao;


    await this.delay(1000);
    this.totalInfinito();
  }
   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

  checkPuntos(){
    if(this.vato.nivelAcademico=='MEDIO SUPERIOR' || this.vato.nivelAcademico=="TECNICO SUPERIOR UNIVERSITARIO TSU"){
      this.totalAcademico=0;
      return 0;
    }else if(this.vato.nivelAcademico=='SUPERIOR LICENCIATURA' || this.vato.nivelAcademico=="SUPERIOR INGENIERIA" ){
      this.totalAcademico=5;
      return 5;
    }else if(this.vato.nivelAcademico=='MAESTRIA' || this.vato.nivelAcademico=="DOCTORADO" ){
      this.totalAcademico=10;
      return 10;
    }else {
      this.totalAcademico=0;
      return 0;
    }
  }

  checkPuntosRec(){
    var a=<HTMLInputElement>document.getElementById("altoImp");
    var b=<HTMLInputElement>document.getElementById("polic");
    var c=<HTMLInputElement>document.getElementById("asig");

    if(a.checked || b.checked){
    if((this.puntosRecAlto+this.puntosRecPoli)>10){
this.resultao=10;
return 10;

    }else{
      this.resultao=this.puntosRecAlto+this.puntosRecPoli;
      return this.puntosRecAlto+this.puntosRecPoli;
    }
  }else if(c.checked){
    this.resultao=10;
    return 10;
  }else{
    this.resultao=0;
    return 0;
  }
  }

  checkPuntosCap(){
    if(this.vato.capacitaciones==1){
      this.totalConstancias=1;

    }else if(this.vato.capacitaciones==2){
      this.totalConstancias=2;

    }else if(this.vato.capacitaciones==3){
      this.totalConstancias=3;

    }else if(this.vato.capacitaciones==4){
      this.totalConstancias=4;

    }else if(this.vato.capacitaciones==5){
      this.totalConstancias=5;

    }else if(this.vato.capacitaciones==0){
      this.totalConstancias=0;

    }else if(this.vato.capacitaciones>5){
      this.totalConstancias=5;
    }
    var c=<HTMLInputElement>document.getElementById("extra1");
    if(c.checked){
      this.totalConstancias+=3;
      if(this.totalConstancias>5){
        this.totalConstancias=5;
      }

      return this.totalConstancias;
    }else{
      if(this.vato.capacitaciones==1){
        this.totalConstancias=1;

      }else if(this.vato.capacitaciones==2){
        this.totalConstancias=2;

      }else if(this.vato.capacitaciones==3){
        this.totalConstancias=3;

      }else if(this.vato.capacitaciones==4){
        this.totalConstancias=4;

      }else if(this.vato.capacitaciones==5){
        this.totalConstancias=5;

      }else if(this.vato.capacitaciones==0){
        this.totalConstancias=0;

      }else if(this.vato.capacitaciones>5){
        this.totalConstancias=5;
      }

      return this.totalConstancias;
    }

  }

  checkPuntosArr(){
   if(this.vato.arrestos==0){
     this.totalArrestos=5;
    return 5;
   }else if(this.vato.arrestos==1){
    this.totalArrestos=4;
     return 4;
   }else if(this.vato.arrestos==2){
    this.totalArrestos=3;
    return 3;
  }else if(this.vato.arrestos==3){
    this.totalArrestos=2;
    return 2;
  }else if(this.vato.arrestos==4){
    this.totalArrestos=1;
    return 1;
  }else if(this.vato.arrestos>=5){
    this.totalArrestos=0;
    return 0;
  }

  }

  alto(){
    var c=<HTMLInputElement>document.getElementById("asig");
    if(!c.checked){
    var a=<HTMLInputElement>document.getElementById("altoImp");
    if(a.checked){
      (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=false;
      this.altoImpacto="";

    }else{
      (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=true;
      this.altoImpacto="";
      this.puntosRecAlto=0;
    }
  }else{
    alert("una persona puede tener alto impacto y policia del mes, pero no puede tener asignación especial y viceversa");
    var a=<HTMLInputElement>document.getElementById("altoImp");
    a.checked=false;
  }
  }
  poli(){
    var c=<HTMLInputElement>document.getElementById("asig");
    if(!c.checked){
      var a=<HTMLInputElement>document.getElementById("polic");
      if(a.checked){
        (<HTMLInputElement>document.getElementById("polimes")).disabled=false;
        this.poliMes="";
      }else{
        (<HTMLInputElement>document.getElementById("polimes")).disabled=true;
        this.poliMes="";
        this.puntosRecPoli=0;
      }
    }else{
      alert("una persona puede tener alto impacto y policia del mes, pero no puede tener asignación especial y viceversa");
      var a=<HTMLInputElement>document.getElementById("polic");
      a.checked=false;
    }

  }

  ponerTotAlto(){
    if(this.altoImpactoTot!=null){
      this.altoImpacto="Alto impacto "+this.altoImpactoTot;
      if(this.altoImpactoTot==1){
        this.puntosRecAlto+=5;
        this.recono2=1;
      }else if(this.altoImpactoTot>=2){
        this.puntosRecAlto=10;
        this.recono2=1;
      }else if(this.altoImpactoTot<=0){
        this.altoImpacto="";
        this.puntosRecAlto=0;
        this.recono2=0;
      }
    }else{
      this.altoImpacto="";
      this.puntosRecAlto=0;
      this.recono2=0;
    }

  }

  ponerTotPoli(){
    if(this.poliMesTot!=null){
      this.poliMes="policía del mes "+this.poliMesTot;
      if(this.poliMesTot==1){
        this.puntosRecPoli+=5;
        this.recono1=1;
      }else if(this.poliMesTot>=2){
        this.puntosRecPoli=10;
        this.recono1=1;
      }else if(this.poliMesTot<=0){
        this.poliMes="";
      this.puntosRecPoli=0;
      this.recono1=1;
      }
    }else{
      this.poliMes="";
      this.puntosRecPoli=0;
      this.recono1=1;
    }

  }

  checkPuntosAnt(){
    if(this.vato.antiguedad>=2 && this.vato.antiguedad<=5){
      this.totalAntiguedad=3;
     return 3;
    }else if(this.vato.antiguedad>=6 && this.vato.antiguedad<=10){
      this.totalAntiguedad=5;
      return 5;
    }else if(this.vato.antiguedad>=11 && this.vato.antiguedad<=15){
      this.totalAntiguedad=7;
     return 7;
   }else if(this.vato.antiguedad>=16 ){
    this.totalAntiguedad=10;
     return 10;
   }else if(this.vato.antiguedad<2){
    this.totalAntiguedad=0;
     return 0;
   }

   }

}
