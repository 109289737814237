import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare function mensajeErrorIniciarSesion(): any;
declare function easter(): any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  coso1:boolean=false;
  coso2:boolean=false;
  coso3:boolean=false;
  coso4:boolean=false;
  constructor(
    private con: ConexionApiService,
    private cookie: CookieService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.cookie.check('depa')) {
      if(this.cookie.get("depa").toString()=="rh"){
        this.router.navigate(['/rh']);

      }else if(this.cookie.get("depa").toString()=="comision"){
        this.router.navigate(['/chj']);

      }else if(this.cookie.get("depa").toString()=="maestro"){
        this.router.navigate(['/altaCalifas']);

      }
      //

    }
    this.registerForm = this.formBuilder.group({
      NumEmpleado: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(1)]],
    });

  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  easter(cosillo){
    switch(cosillo){
      case 1: this.coso1=true;
      break;
      case 2:this.coso2=true;
      break;
      case 3:this.coso3=true;
      break;
      case 4:this.coso4=true;
      break;
    }
    if(this.coso1 && this.coso2 && this.coso3 && this.coso4){
      easter();
    }
  }

  redirecto(){
    this.coso1=false;
  this.coso2=false;
  this.coso3=false;
  this.coso4=false;
    this.router.navigate(['/eyfc']);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    //alert('SUCCESS!! :-)\n\n' + this.registerForm.value.NumEmpleado);
    this.obtenerDatos();
  }

  obtenerDatos() {
    this.con
      .obtenerUsuario(
        this.registerForm.value.NumEmpleado,
        this.registerForm.value.password
      )
      .subscribe((result) => {
        if (result[0] != null){

          if(result[0].departamento=="comision"){
          this.cookie.set('impe', result[0].impe);
          this.cookie.set('nombre',result[0].nombre);
          this.cookie.set('apaterno',result[0].apaterno);
          this.cookie.set('amaterno',result[0].amaterno);
          this.cookie.set('depa',"comision");
          this.router.navigate(['/chj']);
      }else if(result[0].departamento=="rh"){
        this.cookie.set('impe', result[0].impe);
        this.cookie.set('nombre',result[0].nombre);
        this.cookie.set('apaterno',result[0].apaterno);
        this.cookie.set('amaterno',result[0].amaterno);
        this.cookie.set('depa',"rh");
        this.router.navigate(['/rh']);
        }else if(result[0].departamento=="maestro"){
          this.ponerCookies("maestro",result);
          this.router.navigate(['/altaCalifas']);
          }
       } else {
          mensajeErrorIniciarSesion();
        }
      });
  }
  ponerCookies(depa,result){
    this.cookie.set('impe', result[0].impe);
    this.cookie.set('nombre',result[0].nombre);
    this.cookie.set('apaterno',result[0].apaterno);
    this.cookie.set('amaterno',result[0].amaterno);
    this.cookie.set('depa',depa);
  }
}
