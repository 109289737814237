import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare function reloj(): any;
@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.css']
})
export class CalificacionesComponent implements OnInit {
  codigo="";
  datos=null;
  califas=null;
  calificacion=0;
  puntos=0;
  total=0;
  constructor(private con: ConexionApiService,
    private cookie: CookieService,
    private router: Router) { }

  ngOnInit(): void {
    reloj();
  }

  buscar(){
this.con.obtenerRegistrados(this.codigo).subscribe(
  result=>{
    if(result[0]!=null){
      this.datos=result[0];
      this.puntos=parseFloat(result[0].puntuaje);
      this.con.obtenerMateriasPersona(this.datos.impe).subscribe(
        result=>{
          if(result[0]!=null){
            this.califas=result;
            this.calificacion=0;
          this.califas.forEach(a => {
            this.calificacion+=parseFloat(a.calificacion);
          });
          this.total=this.calificacion+this.puntos;
          }else{
            this.califas=null;
            this.calificacion=0;
          }
        }
      );
      this.total=this.calificacion+this.puntos;
    }else{
      this.datos=null;
    }
  }
);
  }

}
