import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare function MensajeRegistroCompleto(x):any;
@Component({
  selector: 'app-rh-b',
  templateUrl: './rh-b.component.html',
  styleUrls: ['./rh-b.component.css']
})
export class RhBComponent implements OnInit {
  impe=null;
  vato={
    impe:null,
    nombre:"",
    apaterno:"",
    amaterno:"",
    puesto:"",
    nivelAcademico:"",
    nivelAcademico2:"",
    capacitaciones:0,
    arrestos:0,
    antiguedad:0,
    capacitaciones2:'',
    arrestos2:'',
    antiguedad2:'',
    reconocimientos:"",
    puntuaje:null,
    impeUsuario:null,
    depa:"BOMBERO",
    depa2:""
  }
  altoImpacto="";
  poliMes="";
  altoImpactoTot=null;
  poliMesTot=null;
  puntosRecAlto=0;
  puntosRecPoli=0;
  resultao=null;
  totalPuntos=0;
  totalAcademico=0;
  totalConstancias=0;
  totalArrestos=0;
  totalAntiguedad=0;
  recono1=0;
  recono2=0;
  folio=null;
    constructor(private con: ConexionApiService,
      private cookie: CookieService,
      private router: Router) { }
      impeRH="";
      nombre="";
      apaterno="";
      amaterno="";
    ngOnInit(): void {
      if(!this.cookie.check("impe")){
        this.router.navigate(['/']);
      }
        this.totalInfinito();
        this.impeRH=this.cookie.get("impe");
        this.nombre=this.cookie.get("nombre");
        this.apaterno=this.cookie.get("apaterno");
        this.amaterno=this.cookie.get("amaterno");
    }

    check($event){
      this.con.obtenerDatos(this.impe).subscribe(
        result=>{
          if(result[0] != null){
            this.resultao=result;

            this.resultao.forEach(a => {
              this.vato.nombre=a.nombre;
              this.vato.apaterno=a.apellido_paterno;
              this.vato.amaterno=a.apellido_materno;
              this.vato.puesto=a.puesto;
              this.vato.nivelAcademico=a.nivelAcademico;
              this.vato.antiguedad=a.antiguedad;
            });

        }else{
          this.vato={
            impe:null,
            nombre:"",
            apaterno:"",
            amaterno:"",
            puesto:"",
            nivelAcademico:"",
            nivelAcademico2:"",
            capacitaciones:0,
            arrestos:0,
            antiguedad:0,
            capacitaciones2:'',
            arrestos2:'',
            antiguedad2:'',
            reconocimientos:"",
            puntuaje:null,
            impeUsuario:null,
            depa:"BOMBERO",
            depa2:""
          }
        }
      }
      );
    }
    mensaje(){
      var a=confirm("Se eliminaran los datos que no hayan sido guardados, ¿desea continuar?")
    if(a){
      window.location.reload();
    }
    }

    cerrarSesion(){
      this.cookie.deleteAll();
      this.router.navigate(['/login']);
    }

    imprimir(){

       window.print();
    }

    guardarDatos(){
      this.vato.puntuaje=this.totalPuntos;
    this.vato.impeUsuario=parseInt(this.impeRH);
    this.vato.impe=this.impe;
    this.vato.nivelAcademico2=this.vato.nivelAcademico+" - Puntos: "+this.totalAcademico;
    this.vato.arrestos2=this.vato.arrestos+" arrestos - Puntos: "+this.totalArrestos;
    this.vato.reconocimientos=""+(this.altoImpacto+" "+this.poliMes+" puntos en total: "+this.checkPuntosRec());
    this.vato.capacitaciones2=this.vato.capacitaciones+" constancias - Puntos: "+this.totalConstancias;
    this.vato.antiguedad2=this.vato.antiguedad+" años - Puntos: "+this.totalAntiguedad;
    this.vato.puntuaje=this.totalPuntos;

      this.con.altaRegistro(this.vato).subscribe(
        datos=>{
          if(datos[0]!=null){
            MensajeRegistroCompleto(datos[0].id);
            this.folio="PPAGSI"+datos[0].id;
          }else
          if(datos['resultado']!='OK'){
            alert(datos['mensaje']);
          }
        }
      );

    }
    async totalInfinito(){
      this.totalPuntos=this.totalArrestos+this.totalAcademico+this.totalConstancias+this.totalAntiguedad+this.resultao;


      await this.delay(1000);
      this.totalInfinito();
    }
     delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

    checkPuntos(){
      if(this.vato.nivelAcademico=='MEDIO SUPERIOR' || this.vato.nivelAcademico=="TECNICO SUPERIOR UNIVERSITARIO TSU"){
        this.totalAcademico=5;
        return 5;
      }else if(this.vato.nivelAcademico=='SUPERIOR LICENCIATURA' || this.vato.nivelAcademico=="SUPERIOR INGENIERIA" ){
        this.totalAcademico=10;
        return 10;
      }else if(this.vato.nivelAcademico=='MAESTRIA' || this.vato.nivelAcademico=="DOCTORADO" ){
        this.totalAcademico=10;
        return 10;
      }else {
        this.totalAcademico=0;
        return 0;
      }
    }

    checkPuntosRec(){
      if((this.puntosRecAlto+this.puntosRecPoli)>10){
  this.resultao=10;
  return 10;

      }else{
        this.resultao=this.puntosRecAlto+this.puntosRecPoli;
        return this.puntosRecAlto+this.puntosRecPoli;
      }

    }

    checkPuntosCap(){
      if(this.vato.capacitaciones==1){
        this.totalConstancias=1;
        return 1;
      }else if(this.vato.capacitaciones==2){
        this.totalConstancias=2;
        return 2;
      }else if(this.vato.capacitaciones==3){
        this.totalConstancias=3;
        return 3;
      }else if(this.vato.capacitaciones==4){
        this.totalConstancias=4;
        return 4;
      }else if(this.vato.capacitaciones==5){
        this.totalConstancias=5;
        return 5;
      }else if(this.vato.capacitaciones==0){
        this.totalConstancias=0;
        return 0;
      }else if(this.vato.capacitaciones>5){
        this.totalConstancias=5;
        return 5;
      }

    }

    checkPuntosArr(){
     if(this.vato.arrestos==0){
       this.totalArrestos=5;
      return 5;
     }else if(this.vato.arrestos>=1){
      this.totalArrestos=0;
       return 0;
     }
    }

    alto(){
      var a=<HTMLInputElement>document.getElementById("altoImp");
      if(a.checked){
        (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=false;
        this.altoImpacto="";

      }else{
        (<HTMLInputElement>document.getElementById("altoImpacto")).disabled=true;
        this.altoImpacto="";
        this.puntosRecAlto=0;
      }
    }
    poli(){
      var a=<HTMLInputElement>document.getElementById("polic");
      if(a.checked){
        (<HTMLInputElement>document.getElementById("polimes")).disabled=false;
        this.poliMes="";
      }else{
        (<HTMLInputElement>document.getElementById("polimes")).disabled=true;
        this.poliMes="";
        this.puntosRecPoli=0;
      }
    }

    ponerTotAlto(){
      if(this.altoImpactoTot!=null){
        this.altoImpacto="Medalla al valor "+this.altoImpactoTot;
        if(this.altoImpactoTot==1){
          this.puntosRecAlto+=5;
          this.recono2=1;
        }else if(this.altoImpactoTot>=2){
          this.puntosRecAlto=10;
          this.recono2=1;
        }else if(this.altoImpactoTot<=0){
          this.altoImpacto="";
        this.puntosRecAlto=0;
        this.recono2=0;
        }
      }else{
        this.altoImpacto="";
        this.puntosRecAlto=0;
        this.recono2=0;
      }

    }

    ponerTotPoli(){
      if(this.poliMesTot!=null){
        this.poliMes="Bombero del mes "+this.poliMesTot;
        if(this.poliMesTot==1){
          this.puntosRecPoli+=5;
          this.recono1=1;
        }else if(this.poliMesTot>=2){
          this.puntosRecPoli=10;
          this.recono1=1;
        }else if(this.poliMesTot<=0){
          this.poliMes="";
          this.puntosRecPoli=0;
          this.recono1=1;
        }
      }else{
        this.poliMes="";
        this.puntosRecPoli=0;
        this.recono1=1;
      }

    }

    checkPuntosAnt(){
      if(this.vato.antiguedad>=3 && this.vato.antiguedad<=9){
        this.totalAntiguedad=3;
       return 3;
      }else if(this.vato.antiguedad>=10 && this.vato.antiguedad<=14){
        this.totalAntiguedad=5;
        return 5;
      }else if(this.vato.antiguedad>=15 && this.vato.antiguedad<=19){
        this.totalAntiguedad=7;
       return 7;
     }else if(this.vato.antiguedad>=20 ){
      this.totalAntiguedad=10;
       return 10;
     }else if(this.vato.antiguedad<3){
      this.totalAntiguedad=0;
       return 0;
     }

     }

}
