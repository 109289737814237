import { Component, OnInit } from '@angular/core';
import { ConexionApiService } from '../conexion-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-eyfc',
  templateUrl: './eyfc.component.html',
  styleUrls: ['./eyfc.component.css']
})
export class EyfcComponent implements OnInit {
  datos=null;
  datos2=null;
  materias=null;
  materiasp=null;
  materiasb=null;
  nombreMateria="";
  nombreGrupo="";
  depa="POLICÍA";
  depa2="POLICÍA";
  carpeta="";
  persona=null;
  califas=null;
  calificacion=0;
  calificaciones=null;
  grupos=null;
  constructor(private con: ConexionApiService,
    private cookie: CookieService,
    private router: Router) { }

  ngOnInit(): void {
    
    this.coso();
    
  }


 async  guardarMateria(){
  await this.guardar();
  this.obtenerMaterias();

  }
  async  guardarGrupo(){
    await this.guardarG();
    this.obtenerGrupos();
  
    }

  obtenerMaterias(){
    return new Promise((resolve) => {
this.con.obtenerMaterias().subscribe(
  result=>{
    if(result[0]!=null){
     
      this.materias=result;
      resolve(true);
    }
  }
  
);

});
  }

  obtenerGrupos(){
    return new Promise((resolve) => {
this.con.obtenerGrupos().subscribe(
  result=>{
    if(result[0]!=null){
     
      this.grupos=result;
      resolve(true);
    }
  }
  
);

});
  }


  guardar(){
    return new Promise((resolve) => {
      this.con.altaMateria(this.nombreMateria,this.depa).subscribe(
        datos=>{
          if(datos['resultado']=='OK'){
            alert(datos['mensaje']);
            resolve(true);
          }else{
            alert(datos['mensaje']);
            resolve(true);
          }
        }
      );
    });

  }

  guardarG(){
    return new Promise((resolve) => {
      this.con.altaGrupo(this.nombreGrupo,this.depa2).subscribe(
        datos=>{
          if(datos['resultado']=='OK'){
            alert(datos['mensaje']);
            resolve(true);
          }else{
            alert(datos['mensaje']);
            resolve(true);
          }
        }
      );
    });

  }

  actualizarGrupo(se,id){
    this.con.ponerGrupo(se,id).subscribe(
      datos=>{
        if(datos['resultado']=='OK'){
          console.log(datos['mensaje']);
          this.obtenerCalificacionesGeneral();
        }else{
          console.log(datos['mensaje']);
        }
      }
    );
  }

 async coso(){
   await this.obtenerMaterias();
   await this.obtenerCalificacionesGeneral();
   await this.obtenerGrupos();
   await this.obtenerDatos();

  }

  obtenerCalificacionesGeneral(){
    return new Promise((resolve) => {
this.con.obtenerCalificacionesGeneral().subscribe(
  result=>{
    if(result[0]!=null){
      $('#tablilla3').DataTable().clear();
            $('#tablilla3').DataTable().destroy();
      this.calificaciones=result;
      resolve(true);
    }
  }
  
);

});
  }

  obtenerDatos(){
    return new Promise((resolve) => {
      this.con.obtenerDatosEnse("POLICÍA").subscribe(
        result=>{
          if(result[0]!=null){
           
            this.datos=result;


          }
        }
      );
      this.con.obtenerDatosEnse("BOMBERO").subscribe(
        result=>{
          if(result[0]!=null){
            this.datos2=result;
resolve(true);

          }
        }
      );
    });

  }
  poner(a,b,c,d,e){
    this.carpeta="Tiene carpeta abierta: "+a+" <br/>Numero expediente: "+b+" <br/>Asunto: "+c+" <br/>Fecha: "+d;
    this.persona=e;
    this.con.obtenerMateriasPersona(e.impe).subscribe(
      result=>{
        if(result[0]!=null){
          this.califas=result;
         this.calificacion=0;
          this.califas.forEach(a => {
            this.calificacion+=parseFloat(a.calificacion);
          });
        }else{
          this.califas=null;
        }
      }
    );
  }

  cambiar(a){
    document.getElementById("botonCerrar").click();
    var coso=(<HTMLInputElement>document.getElementById(a));
    if(coso.checked){

      this.con.actualizarEstatus(1,a).subscribe(
        datos=>{
          if(datos['resultado']=='OK'){
            console.log(datos['mensaje']);
            document.getElementById("botonCerrar").click();
          }else{
            console.log(datos['mensaje']);
          }
        }
      );
    }else{

      this.con.actualizarEstatus(0,a).subscribe(
        datos=>{
          if(datos['resultado']=='OK'){
            console.log(datos['mensaje']);
            document.getElementById("botonCerrar").click();
          }else{
            console.log(datos['mensaje']);
          }
        }
      );
    }
    document.getElementById("botonCerrar").click();
  }


  iniciar() {

    $('#tablilla').DataTable({
      dom: 'Blfrtip',
      "autoWidth": true,
      retrieve:true,
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons: ['copy', 'excel', 'csv'],
        },
      ],
    });

  }

  iniciar2() {
    $('#tablilla2').DataTable({
      dom: 'Blfrtip',
      "autoWidth": true,
      retrieve:true,
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons: ['copy', 'excel', 'csv'],
        },
      ],
    });

  }

  iniciar3() {
    
    $('#tablilla3').DataTable({
      dom: 'Blfrtip',
      "autoWidth": true,
      retrieve:true,
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, 'All'],
      ],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
      },
      buttons: [
        {
          extend: 'collection',
          text: 'Export',
          buttons: ['copy', 'excel', 'csv'],
        },
      ],
    });

  }

  obtenerCalificaciones(){

  }



}
